




























































































































































import {Component, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AuditUserLogStatus,
  BbsPostDto,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  UserAuditInput,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  components: {
    ExportButton,
    PagedTableView,
    AbSelect,
  },
})
export default class BbsPost extends Vue {
  editId = 0;
  queryForm = {
    groupName: "",
    groupType: "",
    realName: "",
    auditStatus: "",
    createTime: undefined,
  };

  groupTypeList: DataDictionaryDto[] = [];
  exportFieldList = [
    "发帖人姓名",
    "主贴标题",
    "主贴类型",
    "群组名称",
    "群组类型",
    "评论时间",
    "主贴内容",
    "评论数",
  ];
  created() {
    this.fetchData(this.queryForm);
    this.fetchGroupTypeDataDictionary();
  }

  fetchData(params: any) {
    return api.bbsPost.getAll(params);
  }

  jumpDetail(index: number, row: BbsPostDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "bbsPostDetail",
      params: {id: row.id!.toString()},
    });
  }

  fetchGroupTypeDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "BbsGroupType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.groupTypeList = res!.items!;
      });
  }

  handleDelete(index: number, row: BbsPostDto) {
    this.$confirm("您确认要主贴吗？", "提示").then(() => {
      api.bbsPost.delete({id: row.id}).then(() => {
        this.$message.success("删除成功!");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleStickyPost(index: number, row: BbsPostDto) {
    this.$confirm("您确定要置顶吗？", "提示").then(() => {
      api.bbsPost.stickyPost({body: {id: row.id}}).then(() => {
        this.$message.success("置顶成功!");
        this.fetchData(this.queryForm);
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  handleNotStickyPost(index: number, row: BbsPostDto) {
    this.$confirm("您确定要取消置顶吗？", "提示").then(() => {
      api.bbsPost.stickyPost({body: {id: row.id}}).then(() => {
        this.$message.success("取消成功!");
        (this as any).$bus.$emit("refresh-data");
        this.fetchData(this.queryForm);
      });
    });
  }

  jumpToBbsPostComment(row: BbsPostDto) {
    this.$router.push({
      name: "bbsPostComment",
      query: {postId: row.id!.toString()},
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  selection: UserAuditInput[] = [];
  handleSelectionChange(e: BbsPostDto[]) {
    this.selection = [];

    if (e.length > 0) {
      this.selection = e.map((x: BbsPostDto) => {
        return {
          hostId: x.id + "",
        };
      });
      console.log("selection is :", this.selection);
    }
  }

  handleBatchAudited(isPass:boolean) {
    let postData = this.selection.map((x) => {
      x.desc = isPass?"批量审核通过":"批量审核拒绝";
      x.status = isPass?AuditUserLogStatus.Pass:AuditUserLogStatus.Reject;
      return x;
    });
    console.log(postData);
    api.bbsPost.batchAudit({body: {list: postData}})
      .then(() => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
  }

}
